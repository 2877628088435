import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Mock shadcn/ui components
const Card = ({ children, className }) => <div className={`bg-white shadow-md rounded-lg ${className}`}>{children}</div>;
const CardHeader = ({ children }) => <div className="p-4 border-b">{children}</div>;
const CardContent = ({ children }) => <div className="p-4">{children}</div>;
const Tabs = ({ children, value, onValueChange }) => (
  <div className="mb-4">
    {React.Children.map(children, child => React.cloneElement(child, { active: child.props.value === value, onValueChange }))}
  </div>
);
const TabsContent = ({ children, value, active }) => active ? <div>{children}</div> : null;
const TabsList = ({ children }) => <div className="flex mb-4">{children}</div>;
const TabsTrigger = ({ children, value, active, onValueChange }) => (
  <button
    className={`px-4 py-2 ${active ? 'bg-blue-500 text-white' : 'bg-gray-200'}`}
    onClick={() => onValueChange(value)}
  >
    {children}
  </button>
);
const Badge = ({ children, className }) => (
  <span className={`px-2 py-1 rounded-full text-sm ${className}`}>{children}</span>
);

// Mock Lucide React icons
const CheckCircle = () => <span className="text-green-500">✓</span>;
const XCircle = () => <span className="text-red-500">✗</span>;
const ArrowRight = () => <span className="text-blue-500">→</span>;

const modelData = [
  { name: 'Claude 3.5 Sonnet', features: 14, score: 0 },
  { name: 'ChatGPT-4', features: 14, score: 0 }
];

const featureComparison = [
  { feature: 'Natural Language Understanding', claude: true, gpt4: true },
  { feature: 'Code Generation', claude: true, gpt4: true },
  { feature: 'Multilingual Support', claude: true, gpt4: true },
  { feature: 'Text Summarization', claude: true, gpt4: true },
  { feature: 'Question Answering', claude: true, gpt4: true },
  { feature: 'Creative Writing', claude: true, gpt4: true },
  { feature: 'Data Analysis', claude: true, gpt4: true },
  { feature: 'Task Planning', claude: true, gpt4: true },
  { feature: 'Image Understanding', claude: true, gpt4: true },
  { feature: 'Conversation Memory', claude: false, gpt4: true },
  { feature: 'Real-time Information', claude: false, gpt4: true },
  { feature: 'Long-term Memory', claude: false, gpt4: true },
  { feature: 'Web Browsing', claude: false, gpt4: true },
  { feature: 'Custom Training', claude: false, gpt4: true },
];

const ModelComparison = () => {
  const [activeTab, setActiveTab] = useState('features');
  const [animatedScores, setAnimatedScores] = useState([0, 0]);
  const [highlightedFeature, setHighlightedFeature] = useState(null);

  const calculateScores = () => {
    const claudeScore = featureComparison.filter(f => f.claude).length;
    const gpt4Score = featureComparison.filter(f => f.gpt4).length;
    return [
      { ...modelData[0], score: claudeScore },
      { ...modelData[1], score: gpt4Score }
    ];
  };

  const scoredModelData = calculateScores();
  const winner = scoredModelData[0].score > scoredModelData[1].score ? 'Claude 3.5 Sonnet' : 
                 scoredModelData[0].score < scoredModelData[1].score ? 'ChatGPT-4' : 'Tie';

  useEffect(() => {
    const timer = setInterval(() => {
      setAnimatedScores(prev => {
        const newScores = [
          Math.min(prev[0] + 1, scoredModelData[0].score),
          Math.min(prev[1] + 1, scoredModelData[1].score)
        ];
        if (newScores[0] === scoredModelData[0].score && newScores[1] === scoredModelData[1].score) {
          clearInterval(timer);
        }
        return newScores;
      });
    }, 100);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="p-4 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold mb-6 text-center">AI Model Comparison</h1>
      <h2 className="text-xl font-semibold mb-4 text-center">Claude 3.5 Sonnet vs ChatGPT-4</h2>
      
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        
        
        <TabsContent value="features" active={activeTab === 'features'}>
          <Card>
            <CardHeader>Feature Comparison</CardHeader>
            <CardContent>
              <table className="w-full">
                <thead>
                  <tr>
                    <th className="text-left">Feature</th>
                    <th className="text-center">Claude 3.5 Sonnet</th>
                    <th className="text-center">ChatGPT-4</th>
                  </tr>
                </thead>
                <tbody>
                  {featureComparison.map((item, index) => (
                    <tr 
                      key={index} 
                      className={`
                        transition-colors duration-300 ease-in-out 
                        ${index % 2 === 0 ? 'bg-gray-100' : ''} 
                        ${highlightedFeature === index ? 'bg-blue-100' : ''}
                      `}
                      onMouseEnter={() => setHighlightedFeature(index)}
                      onMouseLeave={() => setHighlightedFeature(null)}
                    >
                      <td className="py-2">{item.feature}</td>
                      <td className="text-center">
                        {item.claude ? <CheckCircle /> : <XCircle />}
                      </td>
                      <td className="text-center">
                        {item.gpt4 ? <CheckCircle /> : <XCircle />}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardContent>
          </Card>
        </TabsContent>
        
        <TabsContent value="chart" active={activeTab === 'chart'}>
          <Card>
            <CardHeader>Score Comparison</CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={scoredModelData.map((item, index) => ({ ...item, score: animatedScores[index] }))}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="score" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </TabsContent>
      </Tabs>
      
      <Card className="mt-6">
        <CardHeader>Comparison Result</CardHeader>
        <CardContent>
          <div className="flex items-center justify-between">
            <div>
              <p className="text-lg font-semibold mb-2">Claude 3.5 Sonnet</p>
              <p className="text-3xl font-bold">{animatedScores[0]} / 14</p>
            </div>
            <ArrowRight />
            <div>
              <p className="text-lg font-semibold mb-2">ChatGPT-4</p>
              <p className="text-3xl font-bold">{animatedScores[1]} / 14</p>
            </div>
          </div>
          <div className="mt-4 text-center">
            <p className="text-lg">
              Winner: <Badge className="ml-2 text-lg bg-blue-100 text-blue-800">{winner}</Badge>
            </p>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ModelComparison;