// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import TicTacToe from './components/TicTacToe';
import SnakeGame from './components/SnakeGame';
import './index.css';

import ProjectileMotion from './components/ProjectileMotion';
import MicrosoftQ3Infographic from './components/Infographics';
import ParticleSimulation from './components/ParticleSimulation.js';
import Presentation from './components/Presentation.js';
import ModelComparison from './components/ModelComparison.js';






function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tic-tac-toe" element={<TicTacToe />} />
        <Route path="/snake-game" element={<SnakeGame />} />
        <Route path="/projectile" element={<ProjectileMotion />} />
        <Route path="/infographics" element={<MicrosoftQ3Infographic />} />
        <Route path="/particles" element={<ParticleSimulation />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/model-comparison" element={<ModelComparison />} />
        

       
        
        
      </Routes>
    </Router>
  );
}

export default App;
