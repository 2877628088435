import React, { useState, useEffect } from 'react';

const slides = [
  {
    title: "Introduction to Claude 3.5 Sonnet",
    content: "Claude 3.5 Sonnet is an advanced AI model developed by Anthropic. It's part of the Claude 3 family, known for its intelligence and versatility.",
    color: "#E6F3FF"
  },
  {
    title: "Key Features",
    content: [
      "Advanced language understanding and generation",
      "Ability to handle complex tasks and reasoning",
      "Proficiency in multiple languages",
      "Strong contextual awareness",
      "Robust ethical guidelines"
    ],
    color: "#E6FFE6"
  },
  {
    title: "Differences from Other GPT Models",
    content: [
      "More advanced reasoning capabilities",
      "Stronger adherence to ethical guidelines",
      "Better at maintaining context over long conversations",
      "Improved ability to understand and generate nuanced responses",
      "Part of a specialized model family (Claude 3) with different strengths"
    ],
    color: "#FFFDE6"
  },
  {
    title: "Use Cases",
    content: [
      "Complex problem-solving",
      "Creative writing and brainstorming",
      "Code generation and debugging",
      "Data analysis and interpretation",
      "Educational support and tutoring"
    ],
    color: "#F3E6FF"
  },
  {
    title: "Ethical Considerations",
    content: "Claude 3.5 Sonnet is designed with strong ethical principles. It avoids harmful or biased outputs, respects privacy, and aims to be transparent about its limitations as an AI.",
    color: "#FFE6E6"
  }
];

const Presentation = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    setIsAnimating(true);
    const timer = setTimeout(() => setIsAnimating(false), 500);
    return () => clearTimeout(timer);
  }, [currentSlide]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundColor: '#f0f0f0',
      padding: '1rem',
      fontFamily: 'Arial, sans-serif'
    }}>
      <div style={{
        width: '100%',
        maxWidth: '800px',
        backgroundColor: slides[currentSlide].color,
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.5s ease-in-out',
        transform: isAnimating ? 'scale(0.95)' : 'scale(1)',
        opacity: isAnimating ? 0.5 : 1
      }}>
        <div style={{ padding: '1.5rem' }}>
          <h2 style={{ fontSize: '2rem', marginBottom: '0.5rem' }}>{slides[currentSlide].title}</h2>
          <p style={{ fontSize: '1rem', color: '#666' }}>Slide {currentSlide + 1} of {slides.length}</p>
        </div>
        <div style={{ padding: '1rem 1.5rem', minHeight: '250px' }}>
          {Array.isArray(slides[currentSlide].content) ? (
            <ul style={{ paddingLeft: '1.5rem' }}>
              {slides[currentSlide].content.map((item, index) => (
                <li key={index} style={{ fontSize: '1.1rem', marginBottom: '0.5rem' }}>{item}</li>
              ))}
            </ul>
          ) : (
            <p style={{ fontSize: '1.1rem' }}>{slides[currentSlide].content}</p>
          )}
        </div>
        <div style={{ padding: '1.5rem', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <button onClick={prevSlide} style={buttonStyle}>← Previous</button>
          <div style={{ flex: 1, margin: '0 1rem', height: '8px', backgroundColor: '#e0e0e0', borderRadius: '4px', overflow: 'hidden' }}>
            <div style={{
              width: `${((currentSlide + 1) / slides.length) * 100}%`,
              height: '100%',
              backgroundColor: '#3b82f6',
              transition: 'width 0.5s ease-in-out'
            }}></div>
          </div>
          <button onClick={nextSlide} style={buttonStyle}>Next →</button>
        </div>
      </div>
    </div>
  );
};

const buttonStyle = {
  padding: '0.5rem 1rem',
  fontSize: '1rem',
  backgroundColor: '#3b82f6',
  color: 'white',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  transition: 'transform 0.1s ease-in-out',
};

export default Presentation;