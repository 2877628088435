import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Sun, Moon } from 'lucide-react';

const lightTheme = {
  backgroundColor: '#f0f4f8',
  primaryColor: '#5a67d8',
  secondaryColor: '#3c366b',
  accentColor: '#f6ad55',
  textColor: '#2d3748',
  shadowColor1: '#ccd4db',
  shadowColor2: '#ffffff'
};

const darkTheme = {
  backgroundColor: '#1a202c',
  primaryColor: '#7f9cf5',
  secondaryColor: '#a3bffa',
  accentColor: '#fbd38d',
  textColor: '#e2e8f0',
  shadowColor1: '#0d1117',
  shadowColor2: '#2d3748'
};

function Home() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const theme = isDarkMode ? darkTheme : lightTheme;

  const neumorphicStyle = {
    backgroundColor: theme.backgroundColor,
    boxShadow: `12px 12px 24px ${theme.shadowColor1}, 
                 -12px -12px 24px ${theme.shadowColor2}`,
    borderRadius: '15px',
    transition: 'all 0.3s ease',
    fontFamily: "'Inter', sans-serif",
  };

  const linkBoxStyle = {
    ...neumorphicStyle,
    padding: '1.5rem',
    margin: '0.75rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    fontWeight: '600',
    fontSize: '1.1rem',
    textAlign: 'center',
    cursor: 'pointer',
    height: '100%',
  };

  const linkBoxHoverStyle = {
    ...linkBoxStyle,
    transform: 'translateY(-5px)',
    boxShadow: `16px 16px 30px ${theme.shadowColor1}, 
                 -16px -16px 30px ${theme.shadowColor2}`,
  };

  const linkBoxActiveStyle = {
    ...linkBoxStyle,
    transform: 'translateY(2px)',
    boxShadow: `inset 8px 8px 16px ${theme.shadowColor1}, 
                 inset -8px -8px 16px ${theme.shadowColor2}`,
  };

  function LinkItem({ to, text }) {
    const [isHovered, setIsHovered] = useState(false);

    const textStyle = {
      background: `linear-gradient(45deg, ${theme.primaryColor}, ${theme.accentColor})`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      color: isHovered ? theme.accentColor : 'transparent',
      transition: 'all 0.3s ease',
    };

    return (
      <Link 
        to={to} 
        style={isHovered ? linkBoxHoverStyle : linkBoxStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onMouseDown={(e) => Object.assign(e.target.style, linkBoxActiveStyle)}
        onMouseUp={(e) => Object.assign(e.target.style, isHovered ? linkBoxHoverStyle : linkBoxStyle)}
      >
        <span style={textStyle}>{text}</span>
      </Link>
    );
  }

  return (
    <div style={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      padding: '3rem',
      backgroundColor: theme.backgroundColor,
      minHeight: '100vh',
      fontFamily: "'Inter', sans-serif",
      color: theme.textColor,
      transition: 'all 0.3s ease',
    }}>
      <div style={{
        position: 'absolute',
        top: '1rem',
        right: '1rem',
        cursor: 'pointer',
        ...neumorphicStyle,
        padding: '0.5rem',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }} onClick={() => setIsDarkMode(!isDarkMode)}>
        {isDarkMode ? <Sun color={theme.textColor} /> : <Moon color={theme.textColor} />}
      </div>
      <h1 style={{
        ...neumorphicStyle,
        fontSize: '2.5rem',
        fontWeight: '700',
        marginBottom: '2rem',
        padding: '1.5rem 2.5rem',
        letterSpacing: '-0.025em',
        color: theme.primaryColor,
      }}>
        Welcome to Claude 3.5 Sonnet Examples
      </h1>

      <div style={{
        ...neumorphicStyle,
        width: '100%',
        maxWidth: '800px',
        marginBottom: '2rem',
        padding: '1rem',
      }}>
        <h2 style={{
          fontSize: '1.5rem',
          fontWeight: '600',
          marginBottom: '1rem',
          color: theme.primaryColor,
        }}>
          Featured Video
        </h2>
        <div style={{
          position: 'relative',
          paddingBottom: '56.25%', // 16:9 aspect ratio
          height: 0,
          overflow: 'hidden',
        }}>
          {!videoError ? (
            <iframe 
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 'none',
              }}
              src="https://www.youtube.com/embed/9q7DTwskaRA" 
              title="YouTube video player" 
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
              allowFullScreen
              onError={() => setVideoError(true)}
            ></iframe>
          ) : (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.backgroundColor,
              color: theme.textColor,
            }}>
              <p>Sorry, the video could not be loaded. Please try again later.</p>
            </div>
          )}
        </div>
      </div>

      <nav style={{
        width: '100%',
        maxWidth: '800px',
      }}>
        <ul style={{
          listStyleType: 'none',
          padding: 0,
          margin: 0,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '1rem',
        }}>
          {[
            { to: "/tic-tac-toe", text: "Play Tic Tac Toe" },
            { to: "/snake-game", text: "Play Snake Game" },
            { to: "/projectile", text: "Projectile Motion Simulator" },
            { to: "/infographics", text: "Infographics - Microsoft -Q3" },
            { to: "/particles", text: "3D Particle Simulation" },
            { to: "/presentation", text: "Presentation - Claude 3.5" },
            { to: "/model-comparison", text: "ChatGPT 4 Vs Claude 3.5" },
            { to: "https://zapiwala.com/", text: "Zapiwala - AI Tool Hub" },
          ].map((link, index) => (
            <li key={index} style={{ height: '100%' }}>
              <LinkItem to={link.to} text={link.text} />
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default Home;