import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { DollarSign, TrendingUp, Users, Cloud } from 'lucide-react';

const MicrosoftQ3Infographic = () => {
  const financialData = [
    { name: 'Revenue', value: 61.9, growth: 17 },
    { name: 'Operating Income', value: 27.6, growth: 23 },
    { name: 'Net Income', value: 21.9, growth: 20 },
  ];

  const segmentData = [
    { name: 'Productivity and Business Processes', value: 19.6, growth: 12 },
    { name: 'Intelligent Cloud', value: 26.7, growth: 21 },
    { name: 'More Personal Computing', value: 15.6, growth: 17 },
  ];

  return (
    <div className="bg-gray-100 p-8 rounded-lg shadow-lg max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8 text-blue-600">Microsoft Q3 2024 Financial Results</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        {financialData.map((item, index) => (
          <div key={index} className="bg-white p-6 rounded-lg shadow">
            <h2 className="text-xl font-semibold mb-2">{item.name}</h2>
            <div className="flex items-center">
              <DollarSign className="text-green-500 mr-2" />
              <span className="text-2xl font-bold">${item.value}B</span>
            </div>
            <div className="flex items-center mt-2">
              <TrendingUp className="text-blue-500 mr-2" />
              <span className="text-lg">+{item.growth}% YoY</span>
            </div>
          </div>
        ))}
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-bold mb-4">Segment Revenue</h2>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={segmentData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="value" fill="#3b82f6" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Key Highlights</h2>
          <ul className="list-disc list-inside">
            <li>Microsoft Cloud revenue: $35.1 billion, up 23% YoY</li>
            <li>Office 365 Commercial revenue growth: 15%</li>
            <li>Azure and other cloud services revenue growth: 31%</li>
            <li>Xbox content and services revenue increased 62%</li>
          </ul>
        </div>
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Other Metrics</h2>
          <div className="flex items-center mb-4">
            <Users className="text-blue-500 mr-2" />
            <span>Microsoft 365 Consumer subscribers: 80.8 million</span>
          </div>
          <div className="flex items-center">
            <Cloud className="text-blue-500 mr-2" />
            <span>Server products and cloud services revenue increased 24%</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicrosoftQ3Infographic;