import React, { useState, useEffect, createContext, useContext } from 'react';

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleTheme = () => {
    setIsDarkMode(!isDarkMode);
  };

  return (
    <ThemeContext.Provider value={{ isDarkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

const useTheme = () => {
  return useContext(ThemeContext);
};

const TicTacToe = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isPlayerTurn, setIsPlayerTurn] = useState(true);
  const [winner, setWinner] = useState(null);
  const [winningLine, setWinningLine] = useState(null);
  const { isDarkMode, toggleTheme } = useTheme();

  const checkWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        setWinningLine(lines[i]);
        return squares[a];
      }
    }
    return null;
  };

  const handleClick = (index) => {
    if (board[index] || winner || !isPlayerTurn) return;

    const newBoard = [...board];
    newBoard[index] = 'X';
    setBoard(newBoard);
    setIsPlayerTurn(false);
  };

  const aiMove = () => {
    const emptyCells = board.reduce((acc, cell, index) => 
      cell === null ? acc.concat(index) : acc, []);
    const move = emptyCells[Math.floor(Math.random() * emptyCells.length)];
    const newBoard = [...board];
    newBoard[move] = 'O';
    setBoard(newBoard);
    setIsPlayerTurn(true);
  };

  useEffect(() => {
    const winner = checkWinner(board);
    if (winner) {
      setWinner(winner);
    } else if (!isPlayerTurn && !winner) {
      const timer = setTimeout(() => {
        aiMove();
      }, 500);
      return () => clearTimeout(timer);
    }
  }, [board, isPlayerTurn]);

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setIsPlayerTurn(true);
    setWinner(null);
    setWinningLine(null);
  };

  const Cell = ({ value, onClick, index }) => (
    <button
      className={`
        w-20 h-20 m-1 text-4xl font-bold rounded-xl 
        focus:outline-none transition-all duration-300 ease-in-out 
        ${value ? 'animate-pop-in cursor-not-allowed' : 'hover:scale-105 hover:shadow-glow'}
        ${winningLine && winningLine.includes(index) ? 'animate-pulse' : ''}
      `}
      style={{
        boxShadow: isDarkMode
          ? 'inset 5px 5px 10px #1a1a1a, inset -5px -5px 10px #2c2c2c'
          : '5px 5px 10px #d1d9e6, -5px -5px 10px #ffffff',
        background: isDarkMode
          ? 'linear-gradient(145deg, #2c2c2c, #1a1a1a)'
          : 'linear-gradient(145deg, #e6e6e6, #ffffff)',
        color: value === 'X' 
          ? (isDarkMode ? '#3498db' : '#2980b9')
          : (isDarkMode ? '#e74c3c' : '#c0392b'),
      }}
      onClick={onClick}
      disabled={value !== null}
    >
      {value}
    </button>
  );

  const ThemeToggleIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
        fill={isDarkMode ? "#FFF" : "#000"}
      />
      <path
        d="M12 6C8.69 6 6 8.69 6 12C6 15.31 8.69 18 12 18V6Z"
        fill={isDarkMode ? "#FFF" : "#000"}
      />
    </svg>
  );

  return (
    <div className={`flex flex-col items-center justify-center min-h-screen transition-colors duration-300 ${
      isDarkMode ? 'bg-gray-900' : 'bg-gray-100'
    }`}>
      <style jsx>{`
        @keyframes glow {
          0% {
            box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
          }
          50% {
            box-shadow: 0 0 20px rgba(52, 152, 219, 0.8);
          }
          100% {
            box-shadow: 0 0 5px rgba(52, 152, 219, 0.5);
          }
        }
        .hover\\:shadow-glow:hover {
          animation: glow 1.5s infinite;
        }
      `}</style>
      <div className="absolute top-4 right-4">
        <button
          className="p-2 rounded-full focus:outline-none transition-all duration-300 ease-in-out transform hover:scale-110"
          style={{
            background: isDarkMode ? '#2c2c2c' : '#e6e6e6',
            boxShadow: isDarkMode
              ? '3px 3px 6px #1a1a1a, -3px -3px 6px #3e3e3e'
              : '3px 3px 6px #d1d9e6, -3px -3px 6px #ffffff',
          }}
          onClick={toggleTheme}
        >
          <ThemeToggleIcon />
        </button>
      </div>
      <h1 className={`mb-6 text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r ${
        isDarkMode ? 'from-blue-400 via-purple-500 to-pink-500' : 'from-purple-400 via-pink-500 to-red-500'
      } animate-color-change`}>
        Tic-Tac-Toe
      </h1>
      <div className={`grid grid-cols-3 gap-1 p-4 rounded-xl shadow-xl transition-all duration-300 ${
        isDarkMode ? 'bg-gray-800' : 'bg-gray-200'
      }`}>
        {board.map((cell, index) => (
          <Cell key={index} value={cell} onClick={() => handleClick(index)} index={index} />
        ))}
      </div>
      {winner && (
        <div className="mt-6 text-2xl font-bold animate-bounce">
          <span className={`text-transparent bg-clip-text bg-gradient-to-r ${
            isDarkMode ? 'from-green-400 to-blue-500' : 'from-green-500 to-blue-600'
          }`}>
            {winner === 'X' ? 'You win!' : 'AI wins!'}
          </span>
        </div>
      )}
      {!winner && board.every((cell) => cell !== null) && (
        <div className="mt-6 text-2xl font-bold animate-pulse">
          <span className={`text-transparent bg-clip-text bg-gradient-to-r ${
            isDarkMode ? 'from-yellow-400 to-orange-500' : 'from-yellow-500 to-orange-600'
          }`}>
            It's a draw!
          </span>
        </div>
      )}
      <button
        className="px-5 py-2 mt-6 text-lg font-bold text-white rounded-full focus:outline-none transition-all duration-300 ease-in-out transform hover:scale-110 animate-glow"
        style={{
          background: isDarkMode
            ? 'linear-gradient(45deg, #2980b9, #8e44ad)'
            : 'linear-gradient(45deg, #3498db, #9b59b6)',
          boxShadow: isDarkMode
            ? '0 0 15px rgba(41, 128, 185, 0.5)'
            : '0 0 15px rgba(52, 152, 219, 0.5)',
        }}
        onClick={resetGame}
      >
        Reset Game
      </button>
    </div>
  );
};

export default function App() {
  return (
    <ThemeProvider>
      <TicTacToe />
    </ThemeProvider>
  );
}