import React, { useState, useEffect, useRef } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CricketBall = ({ cx, cy, scale = 1 }) => (
  <g transform={`translate(${cx},${cy}) scale(${scale})`}>
    <defs>
      <radialGradient id="ballGradient" cx="30%" cy="30%" r="70%" fx="30%" fy="30%">
        <stop offset="0%" stopColor="#ffffff" />
        <stop offset="10%" stopColor="#f0f0f0" />
        <stop offset="60%" stopColor="#d00000" />
        <stop offset="100%" stopColor="#800000" />
      </radialGradient>
      <filter id="ballShadow">
        <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
        <feOffset dx="0" dy="4" result="offsetblur" />
        <feComponentTransfer>
          <feFuncA type="linear" slope="0.4" />
        </feComponentTransfer>
        <feMerge>
          <feMergeNode />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <circle r="10" fill="url(#ballGradient)" filter="url(#ballShadow)" />
    <path d="M-6,-6 Q0,-10 6,-6 M-6,6 Q0,10 6,6" stroke="#ffffff" strokeWidth="1" fill="none" />
    <path d="M-6,-6 Q-10,0 -6,6 M6,-6 Q10,0 6,6" stroke="#ffffff" strokeWidth="1" fill="none" />
  </g>
);

const ProjectileMotionSimulator = () => {
  const [velocity, setVelocity] = useState(50);
  const [angle, setAngle] = useState(45);
  const [trajectoryData, setTrajectoryData] = useState([]);
  const [totalDistance, setTotalDistance] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);
  const [ballPosition, setBallPosition] = useState({ x: 0, y: 0 });
  const [isAnimating, setIsAnimating] = useState(false);
  const animationRef = useRef(null);

  const calculateTrajectory = () => {
    const g = 9.81;
    const radians = (angle * Math.PI) / 180;
    const vx = velocity * Math.cos(radians);
    const vy = velocity * Math.sin(radians);
    
    let t = 0;
    const dt = 0.1;
    const data = [];
    let maxY = 0;

    while (true) {
      const x = vx * t;
      const y = vy * t - 0.5 * g * t * t;
      
      if (y < 0) break;
      
      data.push({ x: parseFloat(x.toFixed(2)), y: parseFloat(y.toFixed(2)) });
      maxY = Math.max(maxY, y);
      t += dt;
    }

    setTrajectoryData(data);
    setTotalDistance(parseFloat(data[data.length - 1].x.toFixed(2)));
    setMaxHeight(parseFloat(maxY.toFixed(2)));
  };

  const runSimulation = () => {
    calculateTrajectory();
    setIsAnimating(true);
    setBallPosition({ x: 0, y: 0 });
  };

  useEffect(() => {
    if (isAnimating && trajectoryData.length > 0) {
      let frame = 0;
      const animateProjectile = () => {
        if (frame < trajectoryData.length) {
          setBallPosition(trajectoryData[frame]);
          frame++;
          animationRef.current = requestAnimationFrame(animateProjectile);
        } else {
          setIsAnimating(false);
        }
      };
      animationRef.current = requestAnimationFrame(animateProjectile);
    }
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [isAnimating, trajectoryData]);

  const maxX = Math.max(...trajectoryData.map(d => d.x), 100);
  const maxY = Math.max(...trajectoryData.map(d => d.y), 50);

  const inputStyle = {
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '5px',
    margin: '5px 0',
    width: '100%'
  };

  const buttonStyle = {
    backgroundColor: '#4CAF50',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '4px 2px',
    cursor: 'pointer',
    borderRadius: '4px'
  };

  const cardStyle = {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '15px',
    margin: '10px 0',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
  };

  return (
    <div style={{ padding: '20px', maxWidth: '600px', margin: '0 auto' }}>
      <h1 style={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '20px' }}>Projectile Motion Simulator</h1>
      <div style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
        <div>
          <label htmlFor="velocity" style={{ display: 'block', marginBottom: '5px' }}>Initial Velocity (m/s)</label>
          <input
            type="number"
            id="velocity"
            value={velocity}
            onChange={(e) => setVelocity(parseFloat(e.target.value))}
            style={inputStyle}
          />
        </div>
        <div>
          <label htmlFor="angle" style={{ display: 'block', marginBottom: '5px' }}>Angle (degrees)</label>
          <input
            type="number"
            id="angle"
            value={angle}
            onChange={(e) => setAngle(parseFloat(e.target.value))}
            style={inputStyle}
          />
        </div>
      </div>
      <button onClick={runSimulation} style={buttonStyle}>Run Simulation</button>
      {trajectoryData.length > 0 && (
        <div style={{ display: 'flex', gap: '20px', marginTop: '20px' }}>
          <div style={cardStyle}>
            <p style={{ fontSize: '14px', fontWeight: 'medium' }}>Total Distance</p>
            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{totalDistance} m</p>
          </div>
          <div style={cardStyle}>
            <p style={{ fontSize: '14px', fontWeight: 'medium' }}>Maximum Height</p>
            <p style={{ fontSize: '24px', fontWeight: 'bold' }}>{maxHeight} m</p>
          </div>
        </div>
      )}
      <div style={{ height: '320px', position: 'relative', marginTop: '20px' }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={trajectoryData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="x" label={{ value: 'Distance (m)', position: 'bottom' }} domain={[0, maxX]} />
            <YAxis label={{ value: 'Height (m)', angle: -90, position: 'left' }} domain={[0, maxY]} />
            <Tooltip />
            <Line type="monotone" dataKey="y" stroke="#8884d8" dot={false} />
          </LineChart>
        </ResponsiveContainer>
        <svg
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          viewBox={`0 0 ${maxX} ${maxY}`}
          preserveAspectRatio="none"
        >
          <CricketBall cx={ballPosition.x} cy={maxY - ballPosition.y} scale={0.5} />
        </svg>
      </div>
    </div>
  );
};

export default ProjectileMotionSimulator;