import React, { useState, useEffect } from 'react';

const Slider = ({ id, min, max, step, value, onValueChange }) => (
  <input
    type="range"
    id={id}
    min={min}
    max={max}
    step={step}
    value={value}
    onChange={(e) => onValueChange([parseFloat(e.target.value)])}
    className="w-full"
  />
);

const Label = ({ htmlFor, children }) => (
  <label htmlFor={htmlFor} className="text-white mb-2 block">
    {children}
  </label>
);

const Button = ({ onClick, children }) => (
  <button
    onClick={onClick}
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
  >
    {children}
  </button>
);

const Particle = ({ x, y, size, color }) => (
  <div
    style={{
      position: 'absolute',
      left: `${x}px`,
      top: `${y}px`,
      width: `${size}px`,
      height: `${size}px`,
      borderRadius: '50%',
      backgroundColor: color,
    }}
  />
);

const ParticleSimulation = () => {
  const [particles, setParticles] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [particleCount, setParticleCount] = useState(100);
  const [speed, setSpeed] = useState(1);
  const [area, setArea] = useState(100);
  const [showControls, setShowControls] = useState(true);

  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    const centerX = dimensions.width / 2;
    const centerY = dimensions.height / 2;
    const areaRadius = Math.min(dimensions.width, dimensions.height) * (area / 200);

    const newParticles = Array.from({ length: particleCount }, () => {
      const angle = Math.random() * 2 * Math.PI;
      const radius = Math.sqrt(Math.random()) * areaRadius;
      return {
        x: centerX + radius * Math.cos(angle),
        y: centerY + radius * Math.sin(angle),
        vx: (Math.random() - 0.5) * 2 * speed,
        vy: (Math.random() - 0.5) * 2 * speed,
        size: Math.random() * 5 + 2,
        color: `hsl(${Math.random() * 360}, 100%, 50%)`,
      };
    });

    setParticles(newParticles);
  }, [dimensions, particleCount, speed, area]);

  useEffect(() => {
    const animateParticles = () => {
      const centerX = dimensions.width / 2;
      const centerY = dimensions.height / 2;
      const areaRadius = Math.min(dimensions.width, dimensions.height) * (area / 200);

      setParticles(prevParticles =>
        prevParticles.map(particle => {
          let { x, y, vx, vy } = particle;
          x += vx;
          y += vy;

          const dx = x - centerX;
          const dy = y - centerY;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance > areaRadius) {
            const angle = Math.atan2(dy, dx);
            x = centerX + areaRadius * Math.cos(angle);
            y = centerY + areaRadius * Math.sin(angle);
            vx = -vx;
            vy = -vy;
          }

          return { ...particle, x, y, vx, vy };
        })
      );
    };

    const intervalId = setInterval(animateParticles, 30);
    return () => clearInterval(intervalId);
  }, [dimensions, speed, area]);

  return (
    <div className="w-full h-screen bg-black overflow-hidden relative">
      {particles.map((particle, index) => (
        <Particle key={index} {...particle} />
      ))}
      <Button
        onClick={() => setShowControls(!showControls)}
        className="absolute top-4 right-4 z-10"
      >
        {showControls ? 'Hide Controls' : 'Show Controls'}
      </Button>
      {showControls && (
        <div className="absolute bottom-4 left-4 right-4 bg-white bg-opacity-20 p-4 rounded-lg">
          <div className="mb-4">
            <Label htmlFor="particle-count">
              Particle Count: {particleCount}
            </Label>
            <Slider
              id="particle-count"
              min={10}
              max={500}
              step={10}
              value={[particleCount]}
              onValueChange={(value) => setParticleCount(value[0])}
            />
          </div>
          <div className="mb-4">
            <Label htmlFor="speed">
              Speed: {speed.toFixed(1)}
            </Label>
            <Slider
              id="speed"
              min={0.1}
              max={5}
              step={0.1}
              value={[speed]}
              onValueChange={(value) => setSpeed(value[0])}
            />
          </div>
          <div>
            <Label htmlFor="area">
              Area: {area}%
            </Label>
            <Slider
              id="area"
              min={10}
              max={100}
              step={1}
              value={[area]}
              onValueChange={(value) => setArea(value[0])}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ParticleSimulation;