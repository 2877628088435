import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AlertCircle, Clock, Heart, Volume2, VolumeX, ArrowUp, ArrowDown, ArrowLeft, ArrowRight } from 'lucide-react';

const GRID_SIZE = 20;
const CELL_SIZE = 20;
const INITIAL_SNAKE = [{ x: 10, y: 10 }];
const INITIAL_DIRECTION = { x: 1, y: 0 };
const INITIAL_FOOD = { x: 15, y: 15 };
const GAME_SPEED = 200;
const KEY_COOLDOWN = 100;
const INITIAL_LIVES = 3;
const COLOR_CHANGE_SPEED = 50; // Lower value for faster color change

const NeonSnakeGame = () => {
  const [snake, setSnake] = useState(INITIAL_SNAKE);
  const [direction, setDirection] = useState(INITIAL_DIRECTION);
  const [food, setFood] = useState(INITIAL_FOOD);
  const [gameOver, setGameOver] = useState(false);
  const [score, setScore] = useState(0);
  const [isStarted, setIsStarted] = useState(false);
  const [timer, setTimer] = useState(0);
  const [lives, setLives] = useState(INITIAL_LIVES);
  const [isMuted, setIsMuted] = useState(false);
  const [showLifeLostModal, setShowLifeLostModal] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [borderHue, setBorderHue] = useState(0);
  const lastKeyPressTime = useRef(0);
  const gameLoopRef = useRef(null);
  const timerLoopRef = useRef(null);
  const audioContextRef = useRef(null);

  useEffect(() => {
    audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
    setIsMobile(window.innerWidth <= 768);
    return () => {
      if (audioContextRef.current.state !== 'closed') {
        audioContextRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    const colorInterval = setInterval(() => {
      setBorderHue(prevHue => (prevHue + 1) % 360);
    }, COLOR_CHANGE_SPEED);

    return () => clearInterval(colorInterval);
  }, []);

  const playSound = useCallback((type, frequency, duration) => {
    if (isMuted || !audioContextRef.current) return;

    const oscillator = audioContextRef.current.createOscillator();
    const gainNode = audioContextRef.current.createGain();

    oscillator.type = type;
    oscillator.frequency.setValueAtTime(frequency, audioContextRef.current.currentTime);

    gainNode.gain.setValueAtTime(0.1, audioContextRef.current.currentTime);
    gainNode.gain.exponentialRampToValueAtTime(0.00001, audioContextRef.current.currentTime + duration);

    oscillator.connect(gainNode);
    gainNode.connect(audioContextRef.current.destination);

    oscillator.start();
    oscillator.stop(audioContextRef.current.currentTime + duration);
  }, [isMuted]);

  const playEatSound = useCallback(() => {
    playSound('sine', 660, 0.1);
    setTimeout(() => playSound('sine', 880, 0.1), 100);
  }, [playSound]);

  const playWallHitSound = useCallback(() => {
    playSound('square', 110, 0.1);
    setTimeout(() => playSound('square', 55, 0.2), 100);
  }, [playSound]);

  const playLifeLostSound = useCallback(() => {
    playSound('sawtooth', 440, 0.1);
    setTimeout(() => playSound('sawtooth', 220, 0.1), 100);
    setTimeout(() => playSound('sawtooth', 110, 0.1), 200);
  }, [playSound]);

  const playGameOverSound = useCallback(() => {
    playSound('sine', 220, 0.3);
    setTimeout(() => playSound('sine', 196, 0.3), 300);
    setTimeout(() => playSound('sine', 175, 0.3), 600);
  }, [playSound]);

  const playMoveSound = useCallback(() => playSound('sine', 440, 0.05), [playSound]);

  const getSnakeSegmentColor = (index, snakeLength) => {
    const hue = (index / snakeLength) * 360;
    return `hsl(${hue}, 100%, 50%)`;
  };

  const moveSnake = useCallback(() => {
    setSnake(prevSnake => {
      const newSnake = [...prevSnake];
      const head = { ...newSnake[0] };
      head.x += direction.x;
      head.y += direction.y;

      if (
        head.x < 0 || head.x >= GRID_SIZE ||
        head.y < 0 || head.y >= GRID_SIZE ||
        newSnake.some(segment => segment.x === head.x && segment.y === head.y)
      ) {
        playWallHitSound();
        setLives(prevLives => {
          if (prevLives > 1) {
            playLifeLostSound();
            setShowLifeLostModal(true);
            return prevLives - 1;
          } else {
            setGameOver(true);
            playGameOverSound();
            return 0;
          }
        });
        return prevSnake; // Return the previous snake state to pause the game
      }

      newSnake.unshift(head);

      if (head.x === food.x && head.y === food.y) {
        setFood({
          x: Math.floor(Math.random() * GRID_SIZE),
          y: Math.floor(Math.random() * GRID_SIZE),
        });
        setScore(prevScore => prevScore + 1);
        playEatSound();
      } else {
        newSnake.pop();
      }

      playMoveSound();
      return newSnake;
    });
  }, [direction, food, playEatSound, playGameOverSound, playLifeLostSound, playMoveSound, playWallHitSound]);

  const handleDirectionChange = useCallback((newDirection) => {
    const currentTime = Date.now();
    if (currentTime - lastKeyPressTime.current < KEY_COOLDOWN) {
      return;
    }
    lastKeyPressTime.current = currentTime;

    setDirection(prev => {
      if (
        (newDirection.x === 0 && prev.x !== 0) ||
        (newDirection.y === 0 && prev.y !== 0)
      ) {
        return newDirection;
      }
      return prev;
    });

    if (!isStarted) {
      setIsStarted(true);
      playMoveSound();
    }
  }, [isStarted, playMoveSound]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      switch (e.key) {
        case 'ArrowUp':
          handleDirectionChange({ x: 0, y: -1 });
          break;
        case 'ArrowDown':
          handleDirectionChange({ x: 0, y: 1 });
          break;
        case 'ArrowLeft':
          handleDirectionChange({ x: -1, y: 0 });
          break;
        case 'ArrowRight':
          handleDirectionChange({ x: 1, y: 0 });
          break;
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleDirectionChange]);

  useEffect(() => {
    if (isStarted && !gameOver && !showLifeLostModal) {
      gameLoopRef.current = setInterval(moveSnake, GAME_SPEED);
      timerLoopRef.current = setInterval(() => setTimer(prev => prev + 1), 1000);
    }

    return () => {
      if (gameLoopRef.current) clearInterval(gameLoopRef.current);
      if (timerLoopRef.current) clearInterval(timerLoopRef.current);
    };
  }, [isStarted, gameOver, moveSnake, showLifeLostModal]);

  const restartGame = () => {
    setSnake(INITIAL_SNAKE);
    setDirection(INITIAL_DIRECTION);
    setFood(INITIAL_FOOD);
    setGameOver(false);
    setScore(0);
    setIsStarted(false);
    setTimer(0);
    setLives(INITIAL_LIVES);
    setShowLifeLostModal(false);
  };

  const continueGame = () => {
    setShowLifeLostModal(false);
    setSnake(INITIAL_SNAKE);
    setDirection(INITIAL_DIRECTION);
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const toggleMute = () => {
    setIsMuted(prev => !prev);
  };

  const neumorphicStyle = {
    backgroundColor: '#2D3748',
    boxShadow: '8px 8px 15px #1A202C, -8px -8px 15px #4A5568',
    borderRadius: '15px',
    padding: '10px 20px',
    color: '#A0AEC0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const TouchControls = () => (
    <div className="grid grid-cols-3 gap-2 mt-4">
      <div></div>
      <button
        className="p-4 bg-gray-700 rounded-full flex items-center justify-center"
        onClick={() => handleDirectionChange({ x: 0, y: -1 })}
      >
        <ArrowUp size={24} />
      </button>
      <div></div>
      <button
        className="p-4 bg-gray-700 rounded-full flex items-center justify-center"
        onClick={() => handleDirectionChange({ x: -1, y: 0 })}
      >
        <ArrowLeft size={24} />
      </button>
      <div></div>
      <button
        className="p-4 bg-gray-700 rounded-full flex items-center justify-center"
        onClick={() => handleDirectionChange({ x: 1, y: 0 })}
      >
        <ArrowRight size={24} />
      </button>
      <div></div>
      <button
        className="p-4 bg-gray-700 rounded-full flex items-center justify-center"
        onClick={() => handleDirectionChange({ x: 0, y: 1 })}
      >
        <ArrowDown size={24} />
      </button>
      <div></div>
    </div>
  );

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 p-4">
      <div className="mb-8 flex flex-wrap justify-between items-center w-full max-w-2xl px-4">
        <div style={neumorphicStyle} className="mb-2 sm:mb-0">
          <Clock className="mr-2" size={24} />
          <span className="text-xl font-bold">{formatTime(timer)}</span>
        </div>
        <div style={{...neumorphicStyle, fontSize: '24px', fontWeight: 'bold'}} className="mb-2 sm:mb-0">
          Score: {score}
        </div>
        <div style={neumorphicStyle} className="mb-2 sm:mb-0">
          {[...Array(lives)].map((_, i) => (
            <Heart key={i} className="ml-1" size={24} fill="currentColor" />
          ))}
        </div>
      </div>
      <div 
        className="relative rounded-lg overflow-hidden transition-all duration-500 ease-in-out"
        style={{
          width: `${GRID_SIZE * CELL_SIZE}px`,
          height: `${GRID_SIZE * CELL_SIZE}px`,
          maxWidth: '100%',
          maxHeight: '70vh',
          boxShadow: `0 0 10px hsl(${borderHue}, 100%, 50%), 0 0 20px hsl(${borderHue}, 100%, 50%)`,
          transform: isStarted ? 'scale(1)' : 'scale(0.9)',
          opacity: isStarted ? 1 : 0.7,
        }}
      >
        <div className="absolute inset-0 bg-gray-900 opacity-50" />
        {snake.map((segment, index) => (
          <div
            key={index}
            className="absolute transition-all duration-200 ease-in-out"
            style={{
              width: `${CELL_SIZE}px`,
              height: `${CELL_SIZE}px`,
              left: `${segment.x * CELL_SIZE}px`,
              top: `${segment.y * CELL_SIZE}px`,
              backgroundColor: getSnakeSegmentColor(index, snake.length),
              borderRadius: '4px',
              transform: `scale(${isStarted ? 1 : 0})`,
            }}
          />
        ))}
        <div
          className="absolute bg-red-500 transition-all duration-300 ease-in-out"
          style={{
            width: `${CELL_SIZE}px`,
            height: `${CELL_SIZE}px`,
            left: `${food.x * CELL_SIZE}px`,
            top: `${food.y * CELL_SIZE}px`,
            boxShadow: '0 0 5px #FF355E, 0 0 10px #FF355E',
            borderRadius: '4px',
            animation: 'pulse 1s infinite',
          }}
        />
      </div>
      {showLifeLostModal && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-300">
          <div className="bg-gray-800 p-8 rounded-lg text-center">
            <h2 className="text-2xl font-bold text-red-500 mb-4">You lost a life!</h2>
            <p className="text-white mb-4">You have {lives} lives remaining.</p>
            <div className="flex justify-center space-x-4">
              <button
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200"
                onClick={continueGame}
              >
                Continue
              </button>
              <button
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
                onClick={restartGame}
              >
                Quit
              </button>
            </div>
          </div>
        </div>
      )}
      {gameOver && (
        <div 
          className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 transition-opacity duration-300"
          style={{ opacity: gameOver ? 1 : 0 }}
        >
          <div className="text-center">
            <h2 
              className="text-4xl font-bold text-red-500 mb-4 animate-bounce"
            >
              Game Over
            </h2>
            <button
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors duration-200 animate-pulse"
              onClick={restartGame}
            >
              Restart
            </button>
          </div>
        </div>
      )}
      <div className="mt-8 text-blue-300 flex flex-wrap items-center justify-between w-full max-w-2xl px-4">
        <div style={neumorphicStyle} className="mb-2 sm:mb-0">
          <AlertCircle className="mr-2" size={24} />
          {isMobile ? 'Use the on-screen buttons to control the snake' : 'Use arrow keys to start and control the snake'}
        </div>
        <button 
          onClick={toggleMute} 
          style={{...neumorphicStyle, cursor: 'pointer'}}
          className="ml-4 mb-2 sm:mb-0"
        >
          {isMuted ? <VolumeX size={24} /> : <Volume2 size={24} />}
        </button>
      </div>
      {isMobile && <TouchControls />}
    </div>
  );
};

export default NeonSnakeGame;